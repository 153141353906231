import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  ${({ color }) => color && `color: ${color}`};
  ${({ position }) => position && { position }};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ background }) => background && background};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ wrap }) => wrap && `flex-wrap: ${wrap}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ margin }) => margin && { margin }};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-right: ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-left: ${marginRight}`};
  ${({ padding }) => padding && { padding }};
  ${({ height }) => height && { height }};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-right: ${paddingLeft}`};
  ${({ paddingRight }) => paddingRight && `padding-left: ${paddingRight}`};
`

const Container = ({
  align,
  children,
  background,
  color,
  direction,
  wrap,
  justify,
  maxWidth = '1280px',
  padding = '1rem',
  position,
  paddingLeft,
  paddingRight,
  paddingBottom,
  paddingTop,
  margin,
  marginLeft,
  marginRight,
  marginBottom,
  marginTop,
  height,
}) => (
  <StyledContainer
    align={align}
    background={background}
    color={color}
    direction={direction}
    height={height}
    justify={justify}
    margin={margin}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginTop={marginTop}
    maxWidth={maxWidth}
    padding={padding}
    paddingBottom={paddingBottom}
    paddingLeft={paddingLeft}
    paddingRight={paddingRight}
    paddingTop={paddingTop}
    position={position}
    wrap={wrap}
  >
    {children}
  </StyledContainer>
)

export default Container
