import { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

// Summary this component allowes to fetch the name of the Dynasties

// #region  Style
const DynastyName = styled.div`
  flex-direction: row;
  height: 100px;
  width: 150px;
  color: lightgrey;
  position: relative;
  z-index: 111;
  font-size: 1.5rem;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  visibility: hidden ;*/
  @media ${minWidthQueries.lg} {
    font-size: 2rem;
  }
  @media ${maxWidthQueries.sm} {
    font-size: 1.2rem;
    margin: 0;
    border-bottom: 0;
  }
  @media ${maxWidthQueries.sm} {
    border: 2px solid white;
    height: 100px;
    width: 300px;
    padding: 10px;
  }
`

const SideButton = styled.div`
  height: 100px;
  width: 100px;
  display: none;
  border-radius: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  @media ${maxWidthQueries.sm} {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
  }
`
const BackgroundImg = styled.img`
  width: 100%;
  height: 20%;
  opacity: 0.2;
  position: absolute;
  display: none;
  z-index: 0;
`

const ElementImg = styled.img`
  height: 100px;
  width: 100px;
  display: flex;
`
// the border animation
const AnimatedBorder = styled.div`
  width: 250px;
  height: auto;
  border: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    animation: animatedBorder 1.5s linear infinite;
    border-radius: 10px;
    ${SideButton} {
      display: flex;
    }
    ${BackgroundImg} {
      display: flex;
    }
    ${DynastyName} {
      visibility: visible;
    }
  }

  @keyframes animatedBorder {
    0% {
      background: linear-gradient(0deg, #c9ac08, #91f6ff);
    }
    6% {
      background: linear-gradient(24deg, #c9ac08, #91f6ff);
    }
    12% {
      background: linear-gradient(48deg, #c9ac08, #91f6ff);
    }
    18% {
      background: linear-gradient(72deg, #c9ac08, #91f6ff);
    }
    24% {
      background: linear-gradient(96deg, #c9ac08, #91f6ff);
    }
    30% {
      background: linear-gradient(120deg, #c9ac08, #91f6ff);
    }
    36% {
      background: linear-gradient(144deg, #c9ac08, #91f6ff);
    }
    42% {
      background: linear-gradient(168deg, #c9ac08, #91f6ff);
    }
    48% {
      background: linear-gradient(192deg, #c9ac08, #91f6ff);
    }
    54% {
      background: linear-gradient(214deg, #c9ac08, #91f6ff);
    }
    60% {
      background: linear-gradient(238deg, #c9ac08, #91f6ff);
    }
    66% {
      background: linear-gradient(262deg, #c9ac08, #91f6ff);
    }
    72% {
      background: linear-gradient(286deg, #c9ac08, #91f6ff);
    }
    78% {
      background: linear-gradient(310deg, #c9ac08, #91f6ff);
    }
    84% {
      background: linear-gradient(334deg, #c9ac08, #91f6ff);
    }
    90% {
      background: linear-gradient(358deg, #c9ac08, #91f6ff);
    }
    96% {
      background: linear-gradient(360deg, #c9ac08, #91f6ff);
    }
    100% {
      background: linear-gradient(0deg, #c9ac08, #91f6ff);
    }
  }
  @media ${maxWidthQueries.sm} {
    ${ElementImg} {
      visibility: hidden;
      display: none;
      height: 0;
      width: 0;
    }
  }
`

// #endregion

// main component to get the name
const DynastyElement = ({ dynasty: { name, slug } }) => {
  return (
    <Link passHref href={`/dynasties/${slug}`}>
      <a>
        <AnimatedBorder>
          <BackgroundImg alt="Background Image" src="/images/bg_image.png" />

          <DynastyName>{name}</DynastyName>
        </AnimatedBorder>
      </a>
    </Link>
  )
}
export default DynastyElement
