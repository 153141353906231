import React from 'react'

const hello = ['hello', 'hi', 'hey', 'sup', 'yo', 'hu', 'howdy']
const launch = [
  'launch',
  'release date',
  'date',
  'when',
  'available',
  'early access',
  'demo',
  'preview',
  'trial',
  'playable',
  'test',
  'trial',
]
const demo = [
  'launch',
  'release date',
  'date',
  'when',
  'available',
  'demo',
  'preview',
  'trial',
  'playable',
  'test',
  'trial',
  'early access',
]
const platforms = ['platform', 'pc', 'steam deck', 'switch', 'xbox', 'ps4', 'ps5', 'console']
const price = ['price', 'cost', 'season pass', 'season', 'pass', 'buy', 'purchase', 'get', 'pay']
const multiplayer = [
  'multiplayer',
  'multi',
  'coop',
  'co-op',
  'server',
  'dedicated',
  'p2p',
  'play with friends',
  'play with others',
  'play with other people',
  'play with other players',
  'play with other gamers',
]
const singleplayer = ['singleplayer', 'single', 'solo', 'alone', 'by myself', 'by yourself']
const offline = ['offline', 'no internet', 'no wifi', 'no connection', 'no internet connection']
const preOrder = [
  'pre-order',
  'preorder',
  'pre order',
  'pre order',
  'pre-purchase',
  'prepurchase',
  'pre purchase',
  'order',
  'purchase',
  'buy',
  'get',
  'pay',
]
const alphaParticipation = [
  'alpha',
  'alpha test',
  'alpha testing',
  'alpha trial',
  'alpha demo',
  'alpha preview',
  'alpha event',
  'alpha access',
  'alpha version',
  'alpha build',
  'alpha version',
  'alpha gameplay',
  'alpha game',
  'alpha version',
]
const buyPlushOnEtsy = [
  'buy plush',
  'buy plushie',
  "plushie's",
  'plushies',
  'plush',
  'plushy',
  'plushies',
  "tshirt's",
  'tshirts',
  't-shirt',
  't-shirts',
  't shirt',
  't shirts',
  "merch's",
  'merch',
  'merchandise',
]
const classes = [
  'class',
  'classes',
  'class system',
  'class systems',
  "RPG's class",
  'RPG class',
  'RPGs class',
  "game class's",
  'game classes',
  'game class',
]
const party = [
  "party's",
  'party',
  'parties',
  "sentius party's",
  'sentius party',
  'sentius parties',
  "companion's",
  'companion',
  'companions',
]
const mountSentius = [
  "mount's",
  'mount',
  'mounts',
  "sentius mount's",
  'sentius mount',
  'sentius mounts',
  "game mount's",
  'game mount',
  'game mounts',
  "mount sentius's",
  'mount sentius',
  'mount sentiuses',
  "sentius's mount",
]
const dim = ['Dimensions', 'Dimension', 'dim']
const whatAreSentius = ['What are Sentius', 'What are Sentiuses', 'sentius']
const tameSentius = ['tame', 'Can I tame monsters']
const resources = ["resource's", 'resource', 'resources', "game resource's", 'game resource']
const sentius = ['sentius per dynasty', 'sentius per']
const MessageParser = ({ children, actions }) => {
  const parse = message => {
    const lowerCaseMessage = message.toLowerCase()
    if (hello.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleHello()
    } else if (launch.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleLaunch()
    } else if (demo.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleDemo()
    } else if (platforms.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handlePlatform()
    } else if (price.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handlePrice()
    } else if (multiplayer.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleMultiplayer()
    } else if (singleplayer.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleSinglePlayer()
    } else if (offline.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleOffline()
    } else if (preOrder.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handlePreOrder()
    } else if (alphaParticipation.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleAlphaParticipation()
    } else if (buyPlushOnEtsy.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleMerch()
    } else if (sentius.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleSentius()
    } else if (classes.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleClasses()
    } else if (party.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleParty()
    } else if (resources.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleResources()
    } else if (mountSentius.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleMountSentius()
    } else if (tameSentius.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleTame()
    } else if (whatAreSentius.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleWhatSentius()
    } else if (dim.some(msg => lowerCaseMessage.includes(msg))) {
      actions.handleDim()
    } else {
      actions.handleSorry()
    }
  }

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions,
          parse,
        })
      })}
    </div>
  )
}

export default MessageParser
