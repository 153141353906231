import Bot from '@components/bot/bot'
import CookiePopup from '@components/cookiePopup/cookiePopup'
import CookiePopupText from '@components/cookiePopup/cookiePopupText'
import Footer from '@components/footer/Footer'
import Header from '@components/header/Header'
import { minWidthQueries } from '@operations/utils/useMediaQuery'
import React from 'react'
import styled from 'styled-components'

const StyledMain = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  font-size: 18px;
  overflow-x: hidden;
  ${({ backgroundColor }) => backgroundColor && `background-color:${backgroundColor}`};
`

const BasicLayout = ({
  children,
  headerLinks,
  footerLinks,
  headerLogo,
  dynastiesList,
  footerLogo,
  socialLinks,
  hidden,
  backgroundColor,
}) => {
  if (hidden) return null
  return (
    <>
      <CookiePopup>
        <CookiePopupText />
      </CookiePopup>

      <Bot />

      <Header dynasties={dynastiesList} links={headerLinks} logo={headerLogo} />

      <StyledMain backgroundColor={backgroundColor}>{children}</StyledMain>

      <Footer links={footerLinks} logo={footerLogo} socialLinks={socialLinks} />
    </>
  )
}

export default BasicLayout
