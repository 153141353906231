import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || '300px'};
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.fontSize.large};
  font-family: ${({ fontFamily, theme }) => fontFamily || theme.font.fontFamily.scText};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.black};
  margin: ${({ margin }) => margin || '4px'};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.teal};
  }
`

const BlackButton = ({
  children,
  onClick,
  width,
  height,
  fontSize,
  fontFamily,
  margin,
  styles,
}) => {
  return (
    <Button
      fontFamily={fontFamily}
      fontSize={fontSize}
      height={height}
      margin={margin}
      style={styles}
      type="button"
      width={width}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
export default BlackButton
