import DynastyCard from '@components/DynastiElements/dynastyNameCard'
import Login from '@components/login/Login'
import { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

// #region
const StyledHeader = styled.header`
  cursor: ${({ theme }) => theme.cursor.default};
  width: 100vw;
  height: 4rem;
  align-items: center;
  display: flex;
  background: rgba(0, 0, 0, 0.58);
  position: fixed;
  z-index: 9;
  @media ${minWidthQueries.md} {
    justify-content: center;
    height: 6rem;
    padding-left: 0;
  }
  @media ${maxWidthQueries.sm} {
    justify-content: space-between;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    width: 100%;
    z-index: 100;
    font-size: 1rem;
    height: 5rem;
  }
`

const StyledLink = styled.a`
  margin: 0.5rem;
  font-size: 1.5rem;
  transition: 0.5s;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
  color: lightgrey;
  display: flex;
  &:hover {
    border-bottom: solid 3px white;
    color: ${({ theme }) => theme.colors.white};
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
  @media ${minWidthQueries.md} {
    margin: 0 2rem;
  }
  @media ${minWidthQueries.lg} {
    margin: 0 80px;
    font-size: 2rem;
  }
  @media ${maxWidthQueries.sm} {
    font-size: 1.2rem;
    margin: 0;
    border-bottom: 0;
  }
`
const SCLogo = styled.img`
  height: 100%;
  width: auto;
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
  @media ${minWidthQueries.md} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`
const Label = styled.div`
  @media ${maxWidthQueries.sm} {
    display: none;
  }
`
const IconWrapper = styled.div`
  @media ${maxWidthQueries.sm} {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const Icons = styled.img`
  display: none;
  @media ${maxWidthQueries.sm} {
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const AnimatedBorderMobile = styled.div`
  @media ${maxWidthQueries.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 6rem;
    width: 100%;
    z-index: 99;
    border-radius: 15px;
    background-size: 1000%;
    background-image: linear-gradient(45deg, #c9ac08, #91f6ff);
    animation: borderAnim 1.5s infinite alternate;
    @keyframes borderAnim {
      0% {
        background-position: 0, 0;
      }
      100% {
        background-position: 100%, 45%;
      }
    }
  }
  :hover {
    border-bottom: 3px solid white;
  }
`

// #endregion

// gets the header and the dynasty list
const Header = ({ links, logo, dynasties, names }) => {
  return (
    <>
      <AnimatedBorderMobile />
      <StyledHeader>
        {/* this is the Logo of the main page */}
        <Link href="/">
          <SCLogo alt="Skyclimbers Logo" src={'/images/s-logo-128.png' || logo} />
        </Link>

        {/* this map gets the mobile icons and the links/names for the header */}
        {links?.map(({ link, label, icon, name }) => (
          <Link key={`${link}_${label}`} passHref href={link}>
            <StyledLink>
              <Label className={label}>{label}</Label>
              {icon && (
                <IconWrapper>
                  <Icons src={icon.url} />
                </IconWrapper>
              )}
            </StyledLink>
          </Link>
        ))}
        {/* Gets the Dynastie Card/ Card holder */}

        <DynastyCard dynastiesList={dynasties} />

        <Login />
      </StyledHeader>
    </>
  )
}

export default Header
