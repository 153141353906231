import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

const TextHeader = styled.h3`
  font-weight: bold;
`
const CookiePopupText = () => {
  return (
    <TextWrapper>
      <TextHeader>Before you join us:</TextHeader>
      <p>
        We use cookies and by clicking Accept you agree that we can store Cookies on your device.
      </p>
      <p>
        If you want to learn more, please read our{' '}
        <Link href="/privacy-policy">
          <a>privacy policy</a>
        </Link>
        .
      </p>
    </TextWrapper>
  )
}
export default CookiePopupText
