import Container from '@components/layout/Container'
import { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import Link from 'next/link'
import React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 1rem 0;
  margin-top: auto;
  @media ${minWidthQueries.md} {
    padding: 2rem 0;
  }
  @media ${maxWidthQueries.md} {
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media ${maxWidthQueries.sm} {
    padding-bottom: 5rem;
  }
  cursor: ${({ theme }) => theme.cursor.default};
`
const StyledImage = styled.img`
  height: 48px;
  transition: opacity 0.2s;
  padding-bottom: 2px;
  &:hover {
    opacity: 0.6;
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
  margin: 0 0 15px;
  @media ${minWidthQueries.md} {
    height: 50px;
    margin: 15px;
  }
`
const StyledLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  order: 1;

  @media ${minWidthQueries.md} {
    order: initial;
  }

  ${StyledImage} {
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.teal};
    padding-bottom: 2px;

    margin: 0 0 15px;
    @media ${minWidthQueries.md} {
      margin: 15px;
      order: initial;
    }

    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
      cursor: ${({ theme }) => theme.cursor.pointer};
    }
  }
`
const ParatopeLogo = styled.img`
  height: 80px;
  @media ${minWidthQueries.md} {
    height: 140px;
  }
`
const StyledSocialLinks = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  @media ${minWidthQueries.md} {
    height: 120px;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
`

const Column = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
`

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  @media ${minWidthQueries.md} {
    align-items: flex-start;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
`
const StyledLink = styled.a`
  transition: border 0.2s;
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;

  margin: 0 0 1rem;
  @media ${minWidthQueries.md} {
    text-decoration: none;
    margin: 0 0 0.5rem 0.5rem;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
  }
`
const Copyright = styled.p`
  font-size: 12px;
  text-align: center;
  line-height: 1.2rem;
`

const Footer = ({ socialLinks, links, logo }) => {
  const theme = useTheme()
  const socialLinksLength = socialLinks?.length
  const leftSocialLinks = socialLinks?.slice(0, Math.floor(socialLinksLength / 2))
  const rightSocialLinks = socialLinks?.slice(Math.floor(socialLinksLength / 2))

  const linksLength = links?.length
  const leftLinks = links?.slice(0, Math.floor(linksLength / 2))
  const rightLinks = links?.slice(Math.floor(linksLength / 2))
  return (
    <StyledFooter>
      <Container>
        <Column width="20%">
          {leftSocialLinks?.map(item => {
            return (
              <StyledSocialLinks target="_blank" key={item.link} href={item.link}>
                <StyledImage alt={item.label} src={item.icon?.url} />
              </StyledSocialLinks>
            )
          })}
        </Column>

        <Column width="60%">
          <TextWrapper>
            {leftLinks?.map(item => {
              return (
                <Link key={item.link} passHref href={item.link}>
                  <StyledLink target="_blank">{item.label}</StyledLink>
                </Link>
              )
            })}
          </TextWrapper>
          <StyledLogo>
            <a href="https://www.paratope.co/">
              <ParatopeLogo
                alt="Paratope Skyclimbers Logo"
                src={logo || theme.images.paratopeSkyclimbersLogo}
              />
            </a>

            <Copyright>
              Copyright © {new Date().getFullYear()} Paratope™ All Rights Reserved.
            </Copyright>
          </StyledLogo>
          <TextWrapper>
            {rightLinks?.map(item => {
              return (
                <Link key={item.link} passHref href={item.link}>
                  <StyledLink>{item.label}</StyledLink>
                </Link>
              )
            })}
          </TextWrapper>
        </Column>

        <Column width="20%">
          {rightSocialLinks?.map(item => {
            return (
              <StyledSocialLinks target="_blank" key={item.link} href={item.link}>
                <StyledImage alt={item.label} src={item.icon?.url} />
              </StyledSocialLinks>
            )
          })}
        </Column>
      </Container>
    </StyledFooter>
  )
}
export default Footer
