import BotAvatar from '@components/bot/bot_styling/bot_avatar'
import { createChatBotMessage } from 'react-chatbot-kit'

const botName = 'AkaBot'

const config = {
  botName,
  customComponents: {
    botAvatar: props => <BotAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: '#827524',
    },

    chatButton: {
      backgroundColor: '#827524',
      borderRadius: '10px',
      boxShadow: '10px 10px 10px 10px #255C6E',
      hover: {
        backgroundColor: 'purple',
      },
    },
    chatContainer: {
      background: 'black',
    },
    chatContainerWidth: {
      width: '100%',
    },
    chatMessageWidth: {
      width: '100%',
    },
  },
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
}
export default config
