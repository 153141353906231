// in ActionProvider.jsx
import React from 'react'

const hello_message = [
  'Hello. Nice to meet you.',
  'Hi. Nice to meet you.',
  'Hey. Nice to meet you.',
  'Sup. Nice to meet you.',
  'Yo. Nice to meet you.',
  'Howdy. Nice to meet you.',
]
const launch_message = [
  'The early access release on PC and Steam Deck is slated for Q4 2023, with console versions to be announced.',
  'In Q4 2023, early access will be available on PC and Steam Deck. Console versions will be announced at a later date.',
  'Early access for PC and Steam Deck is scheduled for Q4 2023. The release date for consoles will be announced.',
  'We are planning to launch early access on PC and Steam Deck in Q4 2023. Stay tuned for the announcement of console versions.',
  'Get ready for early access on PC and Steam Deck in Q4 2023. The release date for consoles will be disclosed soon.',
]
const demo_message = [
  'Preview events such as Skycon or Steam Next Fest will offer limited time trials.',
  'At preview events like Skycon or Steam Next Fest, limited time trials will be provided.',
  'Limited time trials will be made available at preview events such as Skycon or Steam Next Fest.',
  'Events like Skycon or Steam Next Fest will provide opportunities for limited time trials.',
  'At Skycon or Steam Next Fest and similar preview events, limited time trials will be offered.',
  'Limited time trials will be up for grabs at preview events like Skycon or Steam Next Fest.',
  'Preview events, including Skycon or Steam Next Fest, will feature limited time trials.',
  'Limited time trials will be presented at preview events such as Skycon or Steam Next Fest.',
  'Skycon or Steam Next Fest and other preview events will host limited time trials.',
  'Limited time trials will be showcased at preview events like Skycon or Steam Next Fest.',
]

const platforms = [
  'the game will be released on PC, Steam Deck, Switch, Xbox, PS4 , PS5',
  'The game will be available on multiple platforms, including PC, Steam Deck, and consoles.',
  "You'll be able to play the game on a variety of devices, such as PC, Steam Deck, and gaming consoles.",
  'The game will be released on a range of platforms, including PC, Steam Deck, and popular gaming consoles.',
  "You'll have the option to play the game on different devices, such as PC, Steam Deck, and consoles like Xbox and PlayStation.",
  'The game will be available on a variety of platforms, including PC, Steam Deck, and both current and next-gen consoles.',
  "You'll be able to play the game on multiple devices, such as PC, Steam Deck, and consoles like Xbox, PlayStation, and Switch.",
  'The game will be released on a range of platforms, including PC, Steam Deck, and popular gaming consoles like Xbox and PlayStation.',
  "You'll have the option to play the game on different devices, such as PC, Steam Deck, and consoles like Xbox, PlayStation, and Switch.",
  'The game will be available on a variety of platforms, including PC, Steam Deck, and both current and next-gen consoles like Xbox and PlayStation.',
  "You'll be able to play the game on multiple devices, such as PC, Steam Deck, and consoles like Xbox, PlayStation, Switch, and more.",
]
const price = [
  'The game will be priced at $40, with an optional season pass available.',
  'You can purchase the game for $40, with the option to add a season pass.',
  'The game will cost $40, and you can choose to buy a season pass separately.',
  "You'll be able to buy the game for $40, with the option to get a season pass.",
  'The game will be available for $40, with an optional season pass for additional content.',
  'You can get the game for $40, with the choice to add a season pass for extra features.',
  'The game will cost $40, and you can decide whether or not to purchase a season pass.',
  "You'll be able to purchase the game for $40, with the option to get a season pass for more content.",
  'The game will be priced at $40, with an optional season pass for additional gameplay.',
  'You can buy the game for $40, with the choice to add a season pass for extra perks.',
]
const multiplayerOptions = [
  "You'll be able to play the game with P2P Co-op or dedicated servers for multiplayer.",
  'The game will feature both P2P Co-op and dedicated servers for multiplayer.',
  'You can play the game with P2P Co-op or dedicated servers for multiplayer, depending on your preference.',
  'The game will have both P2P Co-op and dedicated servers for multiplayer gameplay.',
  "You'll have the option to play the game with P2P Co-op or dedicated servers for multiplayer mode.",
  'The game will offer both P2P Co-op and dedicated servers for multiplayer sessions.',
  'You can choose to play the game with P2P Co-op or dedicated servers for multiplayer matches.',
  'The game will include both P2P Co-op and dedicated servers for multiplayer gaming.',
  "You'll be able to enjoy the game with P2P Co-op or dedicated servers for multiplayer gameplay.",
  'The game will support both P2P Co-op and dedicated servers for multiplayer sessions.',
]
const singlePlayerOptions = [
  'You can access all content in single player mode.',
  'All game content is available for single player.',
  "You'll be able to enjoy all content in single player.",
  "The game's entire content is accessible in single player mode.",
  'You can play all game content in single player mode.',
  "All of the game's content is available for single player gameplay.",
  "You'll have access to all content in single player mode.",
  "The game's complete content is accessible in single player mode.",
  'You can experience all content in single player mode.',
  'All game content is playable in single player mode.',
]
const offlineOptions = [
  "It's possible to play SC offline.",
  'You can play SC without an internet connection.',
  'SC can be played offline.',
  'Yes, SC is playable offline.',
  "You don't need an internet connection to play SC.",
  'SC can be enjoyed offline.',
  'Yes, you can play SC without being online.',
  'Offline play is available for SC.',
  'You can play SC offline, yes.',
  'SC can be accessed without an internet connection.',
]
const preOrderOptions = [
  "We're not offering preorders at this time.",
  'Unfortunately, preorders are not available at this time.',
  "We're not currently accepting preorders.",
  'Preorders are not an option at this time.',
  "We're sorry, but preorders are not available right now.",
  "At this time, we're not offering preorders.",
  "Unfortunately, we're not accepting preorders at this time.",
  'Preorders are not currently available.',
  "We're not able to offer preorders at this time.",
  'Sorry, but preorders are not an option right now.',
]
const alphaParticipationOptions = [
  'You can participate in the Alpha during Steam events like Skycon.',
  "Yes, you'll be able to join the Alpha during Steam events like Skycon.",
  'During Steam events like Skycon, you can participate in the Alpha.',
  'Yes, you can take part in the Alpha during events like Skycon on Steam.',
  'Participating in the Alpha is possible during Steam events like Skycon.',
  'Yes, during Steam events like Skycon, you can join the Alpha.',
  'The Alpha is available for participation during Steam events like Skycon.',
  'Yes, you can participate in the Alpha during events like Skycon on Steam.',
  "During Steam events like Skycon, you'll be able to participate in the Alpha.",
  'Yes, the Alpha is open for participation during Steam events like Skycon.',
]
const merchOptions = [
  'You can find it on Etsy.',
  'Yes, you can purchase merch on our Etsy.',
  'You can find our merch on Etsy.',
  'Yes, we have merch available on Etsy.',
  'You can find it on our Etsy.',
  'Yes, you can purchase merch on Etsy.',
]
const sentiusOptions = [
  '100 Sentius per Dynasty',
  'There will be 100 Sentius per Dynasty',
  'Each Dynasty will have 100 Sentius',
]
const classOptions = [
  'There are 5 classes available: Mage, Ranger, Paladin, Engineer, and Warg.',
  'You can choose from 5 different classes: Mage, Ranger, Paladin, Engineer, and Warg.',
  'The game features 5 classes: Mage, Ranger, Paladin, Engineer, and Warg.',
  "You'll have 5 class options to choose from: Mage, Ranger, Paladin, Engineer, and Warg.",
  'There are 5 unique classes in the game: Mage, Ranger, Paladin, Engineer, and Warg.',
  'You can play as one of 5 classes: Mage, Ranger, Paladin, Engineer, or Warg.',
  'The game includes 5 distinct classes: Mage, Ranger, Paladin, Engineer, and Warg.',
  "You'll have the choice of 5 classes: Mage, Ranger, Paladin, Engineer, and Warg.",
  'There are 5 different classes to choose from: Mage, Ranger, Paladin, Engineer, and Warg.',
  'The game offers 5 classes: Mage, Ranger, Paladin, Engineer, and Warg.',
]
const sentiusStoringOptions = [
  'You can store up to 3 Sentius on your gauntlet and have unlimited storage away from your character.',
  'Your gauntlet can hold up to 3 Sentius, and you have unlimited storage outside of your character.',
  'With your gauntlet, you can carry up to 3 Sentius, and you have unlimited storage elsewhere.',
  'You have the ability to store up to 3 Sentius on your gauntlet and have unlimited storage away from your character.',
  'Your gauntlet can hold up to 3 Sentius, and you have unlimited storage space outside of your character.',
  'You can store up to 3 Sentius on your gauntlet and have unlimited storage capacity away from your character.',
  'With your gauntlet, you can carry up to 3 Sentius, and you have unlimited storage available elsewhere.',
  'You have the option to store up to 3 Sentius on your gauntlet and have unlimited storage away from your character.',
  'Your gauntlet can hold up to 3 Sentius, and you have unlimited storage potential outside of your character.',
  'You can store up to 3 Sentius on your gauntlet and have unlimited storage opportunities away from your character.',
]

const resourcOptions = [
  'Population, Currency, Food, Wood, Stone, and in later Eras: Energy',
  'The game features Population, Currency, Food, Wood, Stone, and later on, Energy as resources.',
  'The resources in the game include Population, Currency, Food, Wood, Stone, and Energy in later Eras.',
  'Population, Currency, Food, Wood, Stone, and in later Eras: Energy ',
]
const sentiusMountingOptions = [
  'You can mount any Sentius of "large" scale.',
  'Sentius of "large" scale can be mounted, yes.',
  'Mounting Sentius of "large" scale is possible.',
  'Any Sentius of "large" scale can be mounted without issue.',
  'You have the ability to mount Sentius of "large" scale.',
  'Sentius of "large" scale can be mounted with ease.',
  'Mounting Sentius of "large" scale is allowed.',
  'Yes, Sentius of "large" scale can be mounted without any problems.',
  'You can mount Sentius of "large" scale without difficulty.',
  'Sentius of "large" scale can be mounted without any trouble.',
]
const tameOptions = ['Yes, monsters / animals in the game are called Sentius. ']
const whatAreSentiusOptions = [
  'Sentius are the elemental monsters / animals found in the overworld. ',
]
const dimOptions = ['There are three dimensions, the overworld, Ether, and Void. ']
const sorryOptions = ["sorry I can't answer that question, how else can I help?"]
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleResponse = messageArray => {
    const botMessage = createChatBotMessage(
      messageArray[Math.floor(Math.random() * messageArray.length)]
    )

    setState(prev => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }))
  }

  const handleHello = () => handleResponse(hello_message)
  const handleLaunch = () => handleResponse(launch_message)
  const handleDemo = () => handleResponse(demo_message)
  const handlePlatform = () => handleResponse(platforms)
  const handlePrice = () => handleResponse(price)
  const handleMultiplayer = () => handleResponse(multiplayerOptions)
  const handleSinglePlayer = () => handleResponse(singlePlayerOptions)
  const handleOffline = () => handleResponse(offlineOptions)
  const handlePreOrder = () => handleResponse(preOrderOptions)
  const handleAlphaParticipation = () => handleResponse(alphaParticipationOptions)
  const handleMerch = () => handleResponse(merchOptions)
  const handleSentius = () => handleResponse(sentiusOptions)
  const handleClass = () => handleResponse(classOptions)
  const handleStorSentius = () => handleResponse(sentiusStoringOptions)
  const handleResourc = () => handleResponse(resourcOptions)
  const handleMountSentius = () => handleResponse(sentiusMountingOptions)
  const handleTame = () => handleResponse(tameOptions)
  const handleSorry = () => handleResponse(sorryOptions)
  const handleWhatSentius = () => handleResponse(whatAreSentiusOptions)
  const handleDim = () => handleResponse(dimOptions)
  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: {
            handleAlphaParticipation,
            handleClass,
            handleDemo,
            handleDim,
            handleHello,
            handleLaunch,
            handleMerch,
            handleMountSentius,
            handleMultiplayer,
            handleOffline,
            handlePlatform,
            handlePreOrder,
            handlePrice,
            handleResourc,
            handleSentius,
            handleSinglePlayer,
            handleSorry,
            handleStorSentius,
            handleTame,
            handleWhatSentius,
          },
        })
      })}
    </div>
  )
}

export default ActionProvider
