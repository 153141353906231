import React from 'react'
import styled from 'styled-components'

const BotAvatarImg = styled.img`
  width: 50px;
  height: 50px;
`

const BotAvatar = () => (
  <BotAvatarImg alt="Bot Avatar and the image of akeneko" src="images/icons/aka_wave.png/" />
)

export default BotAvatar
