import BlackButton from '@components/button/BlackButton'
import useMediaQuery, { maxWidthQueries } from '@utils/useMediaQuery'
import React from 'react'
import styled from 'styled-components'

const UserIcon = styled.img``
const StyledLink = styled.a`
  color: white;
  background-color: black;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;
  @media ${maxWidthQueries.md} {
    height: 5rem;
    width: 5.5rem;
    display: block;
    ${UserIcon} {
      height: 100%;
      width: 100%;
    }
  }
  @media ${maxWidthQueries.sm} {
    position: fixed;
    top: 0;
    left: 0;
  }
`

const Login = () => {
  const isMobile = useMediaQuery(maxWidthQueries.sm)
  return (
    <StyledLink
      alt="link to the mailchi"
      href="https://mailchi.mp/2b790159b005/skyclimbers"
      rel="noreferrer noopener"
      target="_blank"
    >
      {isMobile ? (
        <UserIcon alt="user login" src="/images/login/login.svg" />
      ) : (
        <BlackButton fontSize="1.25rem" margin="0">
          Sign Up
        </BlackButton>
      )}
    </StyledLink>
  )
}
export default Login
