import useMediaQuery, { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useState } from 'react'
import styled from 'styled-components'

import DynastyElement from './dynastyElement'
import Link from 'next/dist/client/link'

const DynastyCardElement = styled.div`
  align-items: center;
  border-radius: 0 0 10px 10px;
  color: white;
  display: none;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  scale: 0;
  transform: translateY(0px);
`
const HoverEffect = styled.div`
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 96px;
  background: rgba(0, 0, 0, 0.5);

  @media ${maxWidthQueries.sm} {
    display: ${props => (props.menuOpen ? 'block' : 'none')};
    visibility: ${props => (props.menuOpen ? '' : 'hidden')};

    ${DynastyCardElement} {
      overflow: hidden;
      opacity: 1;
      scale: 1;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      background: linear-gradient(#555555, black);
      z-index: 300;
    }
  }
`
// the dynasty text for the pc page mode
const DynastyText = styled.div`
  z-index: 100;
  color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-size: 1.5rem;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
  transition: 500ms;

  @media ${minWidthQueries.lg} {
    font-size: 2rem;
  }
  @media ${maxWidthQueries.sm} {
    font-size: 1.2rem;
    margin: 0;
    border-bottom: 0;
  }
  &:hover {
    transition: linear 0.2s;
  }
`
// the icon for mobile
const DynastyImg = styled.img`
  @media ${maxWidthQueries.sm} {
    height: 100%;
    background: black;
  }
`
const CloseButton = styled.div`
  @media ${maxWidthQueries.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    background: white;
    border: 3px solid white;
    position: fixed;
    top: 0;
    left: 0;
    font-family: Arial;
    font-size: 3em;
    margin: 10px;
    z-index: 1000;
    :hover {
      border: 3px solid grey;
    }
  }
`

// centers the header elements
const CenterElement = styled.div`
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 500ms;
  height: 100%;
  &:hover {
    ${DynastyText} {
      border-bottom: 2px solid white;
      color: white;
    }
    ${DynastyCardElement} {
      display: flex;
      opacity: 1;
      scale: 1;
    }
  }
  @media ${minWidthQueries.md} {
    margin: 0 2rem;
  }
  @media ${minWidthQueries.lg} {
    margin: 0 80px;
  }
  @media ${maxWidthQueries.sm} {
    margin: 10px;
    z-index: 1;
    height: 70px;
    width: 70px;
    background: white;
    &:hover {
      border-radius: 10px;
      border: 5px solid white;
    }
  }
`
const StyledH1 = styled.h1`
  display: none;
  @media ${maxWidthQueries.sm} {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.fontFamily.skyclimbers};
    font-size: 3.5em;
    margin: 20px;
    letter-spacing: 5px;
  }
`

const DynastyCard = ({ dynastiesList }) => {
  const isMobile = useMediaQuery(maxWidthQueries.sm)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <CenterElement>
      {isMobile && (
        <Link href={'/dynasties'}>
          <DynastyImg
            alt="an image of an caste for the different dynasty's"
            src="/images/icons/Kingdom_Icon.png"
          />
        </Link>
      )}
      {!isMobile && (
        <Link href={'/dynasties'}>
          <a>
            <DynastyText>Dynasties</DynastyText>
          </a>
        </Link>
      )}
    </CenterElement>
  )
}
export default DynastyCard
