import { useEffect, useState } from 'react'

export const minWidthQueries = {
  lg: '(min-width: 1280px)',
  md: '(min-width: 641px)',
}
export const maxWidthQueries = {
  md: '(max-width: 1279px)',
  sm: '(max-width: 640px)',
}

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches, query])

  return matches
}

export default useMediaQuery
