import 'react-chatbot-kit/build/main.css'

import ActionProvider from '@components/bot/ActionProvider'
import config from '@components/bot/config'
import MessageParser from '@components/bot/MessageParser'
import React, { useRef, useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import styled from 'styled-components'

const StyledBotHolder = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 350px;
  min-height: 550px;
  overflow: hidden;
  border-radius: 10px 0 0 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ isClosed }) => (isClosed ? 'scale(1)' : 'scale(0)')};
  @media (max-width: 768px) {
    display: none;
  }
  ${Chatbot} {
    width: 100%;
    height: 100%;
  }
  .react-chatbot-kit-chat-container {
    width: 100%;
  }
`
const OpenButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  background-image: url('images/icons/aka_wave.png/');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: ${({ isClosed }) => (isClosed ? 'scale(0)' : 'scale(1)')};
  animation: open_pulse 1s infinite;
  @keyframes open_pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`
const CloseButton = styled.button`
  display: flex;
  height: 30px;
  width: 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  text-align: center;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transition: all 0.3s ease-in-out;
  font-family: Arial, sans-serif;

  :hover {
    cursor: pointer;
    border-radius: 20px;
  }
`
const AkeImg = styled.img`
  width: 50px;
  height: 50px;
`
export default function Bot() {
  const [isClosed, setIsClosed] = useState(null)
  function handleClose(isOpenState) {
    setIsClosed(isOpenState)
  }

  return (
    <>
      <OpenButton isClosed={isClosed} onClick={() => handleClose(true)}>
        <AkeImg alt="ake wave" src="images/icons/aka_wave.png/" />
      </OpenButton>
      <StyledBotHolder isClosed={isClosed}>
        <CloseButton onClick={() => handleClose(false)}>-</CloseButton>
        <Chatbot actionProvider={ActionProvider} config={config} messageParser={MessageParser} />
      </StyledBotHolder>
    </>
  )
}
