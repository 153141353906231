// @ts-nocheck
/* eslint-disable no-undef */
import BlackButton from '@components/button/BlackButton'
import { minWidthQueries } from '@operations/utils/useMediaQuery'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const PopupBox = styled.div`
  position: fixed;
  width: 600px;
  max-width: 100%;
  background: ${({ theme }) => theme.colors.lightGrey};
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10000;
  bottom: 0;
  left: 0;

  animation: scrollIn 0.5s linear;
  animation-timing-function: cubic-bezier(0.2, 0.6, 1, 1);

  @keyframes scrollIn {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 0px;
    }
  }
  @media ${minWidthQueries.md} {
    bottom: 1rem;
    left: 1rem;
    border-radius: 10px;
  }
`
const TextBox = styled.div`
  padding: 1rem;
  height: 90%;
  width: 100%;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overflow-wrap: break-word;
  border-bottom: 2px solid black;
  @media ${minWidthQueries.md} {
    font-size: 1.5em;
    padding: 2rem 1rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
`
const ButtonContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(149 140 148);
`

const CookiePopup = ({ children }) => {
  const [closeCookie, setCloseCookie] = useState(false)
  const accept = () => {
    if (gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      })
    }
    Cookies.set('tracking', 'true', { expires: 7 })
    setCloseCookie(true)
  }
  const decline = () => {
    if (gtag) {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      })
    }
    Cookies.set('tracking', 'false', { expires: 1 })
    setCloseCookie(true)
  }

  useEffect(() => {
    if (!window.gtag) setCloseCookie(true)
    const tracking = Cookies.get('tracking')
    if (tracking === 'true') {
      accept()
    }
  }, [])

  if (closeCookie) return null
  return (
    <PopupBox>
      <TextBox>{children}</TextBox>
      <ButtonContainer>
        <BlackButton onClick={accept}>Accept</BlackButton>
        <BlackButton onClick={decline}>Decline</BlackButton>
      </ButtonContainer>
    </PopupBox>
  )
}
export default CookiePopup
